@font-face {
  font-family: "ciq-icon-set";
  src: url("ciq-icon-set.eot?fxwlkp");
  src: url("ciq-icon-set.eot?fxwlkp#iefix") format("embedded-opentype"),
    url("ciq-icon-set.ttf?fxwlkp") format("truetype"),
    url("ciq-icon-set.woff?fxwlkp") format("woff"),
    url("ciq-icon-set.svg?fxwlkp#ciq-icon-set") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ciq-"],
[class*=" ciq-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* biome-ignore lint/a11y/useGenericFontNames: icons */
  font-family: "ciq-icon-set" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ciq-progress:before {
  content: "\e9a9";
  color: #fff;
}

.ciq-robot:before {
  content: "\e9aa";
  color: #fff;
}

.ciq-close1:before {
  content: "\e9a3";
  color: #fff;
}

.ciq-circle-red:before {
  content: "\e9a4";
  color: #f36960;
}

.ciq-circle-orange:before {
  content: "\e9a5";
  color: #f9a63a;
}

.ciq-circle-green:before {
  content: "\e9a6";
  color: #4cd964;
}

.ciq-exclamation:before {
  content: "\e9a7";
  color: #fff;
}

.ciq-check1:before {
  content: "\e9a8";
  color: #fff;
}

.ciq-red-stars:before {
  content: "\e9a2";
  color: #f36960;
}

.ciq-canopy-details:before {
  content: "\e99f";
  color: #000a3f;
}

.ciq-canopy-questions:before {
  content: "\e9a0";
  color: #000a3f;
}

.ciq-canopy-experts:before {
  content: "\e9a1";
  color: #000a3f;
}

.ciq-arrow:before {
  content: "\e99e";
  color: #80859f;
}

.ciq-type-multi:before {
  content: "\e99b";
  color: #000a3f;
}

.ciq-type-poll:before {
  content: "\e99c";
  color: #000a3f;
}

.ciq-type-video:before {
  content: "\e99d";
  color: #000a3f;
}

.ciq-component-icon-link-url:before {
  content: "\e99a";
  color: #0062ff;
}

.ciq-component-icon-ic-arrow-back:before {
  content: "\e996";
  color: #0062ff;
}

.ciq-stars .path1:before {
  content: "\e997";
  color: rgb(255, 255, 255);
}

.ciq-stars .path2:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(187, 190, 203);
}

.ciq-stars .path3:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(255, 204, 0);
}

.ciq-component-icon-ic-favourite:before {
  content: "\e993";
  color: #7c819c;
}

.ciq-component-icon-ic-student:before {
  content: "\e994";
  color: #7c819c;
}

.ciq-component-icon-ic-favourite-fill:before {
  content: "\e995";
  color: #fc0;
}

.ciq-component-icon-ic-working:before {
  content: "\e991";
}

.ciq-component-icon-ic-headquarters:before {
  content: "\e992";
}

.ciq-component-icon-ic-on-phone:before {
  content: "\e990";
  color: #6157fc;
}

.ciq-approved-transaction:before {
  content: "\e98e";
}

.ciq-edited-transaction:before {
  content: "\e98f";
}

.ciq-component-icon-ic-bookmark-smart-edits:before {
  content: "\e98c";
}

.ciq-component-icon-ic-bookmarks:before {
  content: "\e98d";
}

.ciq-component-icon-ic-description:before {
  content: "\e98a";
}

.ciq-component-icon-ic-full-screen:before {
  content: "\e98b";
}

.ciq-component-icon-ic-skip-15-s-forward:before {
  content: "\e988";
}

.ciq-component-icon-ic-skip-15-s-back:before {
  content: "\e989";
}

.ciq-pause:before {
  content: "\e987";
}

.ciq-bullet-list:before {
  content: "\e982";
}

.ciq-bold:before {
  content: "\e983";
}

.ciq-numbered-list:before {
  content: "\e984";
}

.ciq-ic-favourite-fill:before {
  content: "\e985";
}

.ciq-italic:before {
  content: "\e986";
}

.ciq-docs-transcript .path1:before {
  content: "\e97a";
  color: rgb(97, 87, 252);
}

.ciq-docs-transcript .path2:before {
  content: "\e97b";
  margin-left: -0.75em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-docs-transcript .path3:before {
  content: "\e97c";
  margin-left: -0.75em;
  color: rgb(255, 255, 255);
}

.ciq-docs-transcript .path4:before {
  content: "\e97d";
  margin-left: -0.75em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-component-icon-ic-volume:before {
  content: "\e97e";
}

.ciq-component-icon-ic-skip-next:before {
  content: "\e97f";
}

.ciq-component-icon-ic-skip-previous:before {
  content: "\e980";
}

.ciq-component-icon-ic-play:before {
  content: "\e981";
}

.ciq-component-icon-ic-call-settings-fill:before {
  content: "\e979";
}

.ciq-component-icon-ic-filter-2:before {
  content: "\e977";
}

.ciq-ic-info:before {
  content: "\e976";
}

.ciq-ic-invoice:before {
  content: "\e975";
}

.ciq-chevron-contract:before {
  content: "\e973";
}

.ciq-landscape-icon:before {
  content: "\e974";
}

.ciq-ic-gantt:before {
  content: "\e972";
}

.ciq-docs-calendar .path1:before {
  content: "\e96f";
  color: rgb(97, 87, 252);
}

.ciq-docs-calendar .path2:before {
  content: "\e970";
  margin-left: -0.75em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-docs-calendar .path3:before {
  content: "\e971";
  margin-left: -0.75em;
  color: rgb(255, 255, 255);
}

.ciq-info-solid:before {
  content: "\e96c";
}

.ciq-exclamation-triangle:before {
  content: "\e96d";
}

.ciq-exclamation-circle-solid:before {
  content: "\e96e";
}

.ciq-component-icon-ic-place:before {
  content: "\e96b";
}

.ciq-csv .path1:before {
  content: "\e944";
  color: rgb(0, 172, 84);
}

.ciq-csv .path2:before {
  content: "\e945";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-csv .path3:before {
  content: "\e946";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-csv .path4:before {
  content: "\e947";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-csv .path5:before {
  content: "\e948";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-doc-generic .path1:before {
  content: "\e949";
  color: rgb(0, 98, 255);
}

.ciq-doc-generic .path2:before {
  content: "\e94a";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-doc-generic .path3:before {
  content: "\e94b";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-doc-word .path1:before {
  content: "\e94c";
  color: rgb(0, 98, 255);
}

.ciq-doc-word .path2:before {
  content: "\e94d";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-doc-word .path3:before {
  content: "\e94e";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-doc-word .path4:before {
  content: "\e94f";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-doc-word .path5:before {
  content: "\e950";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-excel .path1:before {
  content: "\e951";
  color: rgb(0, 172, 84);
}

.ciq-excel .path2:before {
  content: "\e952";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-excel .path3:before {
  content: "\e953";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-image .path1:before {
  content: "\e954";
  color: rgb(0, 171, 84);
}

.ciq-image .path2:before {
  content: "\e955";
  margin-left: -0.7470703125em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-image .path3:before {
  content: "\e956";
  margin-left: -0.7470703125em;
  color: rgb(255, 255, 255);
}

.ciq-image .path4:before {
  content: "\e957";
  margin-left: -0.7470703125em;
  color: rgb(255, 255, 255);
}

.ciq-pdf .path1:before {
  content: "\e958";
  color: rgb(245, 54, 54);
}

.ciq-pdf .path2:before {
  content: "\e959";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-pdf .path3:before {
  content: "\e95a";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-pdf .path4:before {
  content: "\e95b";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-pdf .path5:before {
  content: "\e95c";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-presentation .path1:before {
  content: "\e95d";
  color: rgb(245, 54, 54);
}

.ciq-presentation .path2:before {
  content: "\e95e";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-presentation .path3:before {
  content: "\e95f";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-presentation .path4:before {
  content: "\e960";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-xls .path1:before {
  content: "\e961";
  color: rgb(0, 172, 84);
}

.ciq-xls .path2:before {
  content: "\e962";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-xls .path3:before {
  content: "\e963";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-xls .path4:before {
  content: "\e964";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-xls .path5:before {
  content: "\e965";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-zip .path1:before {
  content: "\e966";
  color: rgb(124, 129, 156);
}

.ciq-zip .path2:before {
  content: "\e967";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.ciq-zip .path3:before {
  content: "\e968";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-zip .path4:before {
  content: "\e969";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-zip .path5:before {
  content: "\e96a";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}

.ciq-right:before {
  content: "\e942";
}

.ciq-left:before {
  content: "\e943";
}

.ciq-chevron-up-down:before {
  content: "\e941";
}

.ciq-secure-outline:before {
  content: "\e900";
}

.ciq-repeat:before {
  content: "\e901";
}

.ciq-settings-outline:before {
  content: "\e902";
}

.ciq-reload:before {
  content: "\e903";
}

.ciq-not-visible:before {
  content: "\e904";
}

.ciq-view-fill-2:before {
  content: "\e905";
}

.ciq-analytics:before {
  content: "\e906";
}

.ciq-student-outline:before {
  content: "\e907";
}

.ciq-working:before {
  content: "\e908";
}

.ciq-birthday:before {
  content: "\e909";
}

.ciq-drafts:before {
  content: "\e90a";
}

.ciq-like:before {
  content: "\e90b";
}

.ciq-like-thumb:before {
  content: "\e90c";
}

.ciq-download1:before {
  content: "\e90d";
}

.ciq-friends-request:before {
  content: "\e90e";
}

.ciq-notes:before {
  content: "\e90f";
}

.ciq-task:before {
  content: "\e910";
}

.ciq-saved:before {
  content: "\e911";
}

.ciq-home-outline:before {
  content: "\e912";
}

.ciq-assignments-outline:before {
  content: "\e913";
}

.ciq-close-circle:before {
  content: "\e914";
}

.ciq-refresh:before {
  content: "\e915";
}

.ciq-write-message:before {
  content: "\e916";
}

.ciq-sent:before {
  content: "\e917";
}

.ciq-archive:before {
  content: "\e918";
}

.ciq-doc:before {
  content: "\e919";
}

.ciq-starred:before {
  content: "\e91a";
}

.ciq-download:before {
  content: "\e91b";
}

.ciq-someone-s-activity:before {
  content: "\e91c";
}

.ciq-latest-activity:before {
  content: "\e91d";
}

.ciq-refund:before {
  content: "\e91e";
}

.ciq-description:before {
  content: "\e91f";
}

.ciq-block:before {
  content: "\e920";
}

.ciq-email:before {
  content: "\e921";
}

.ciq-support:before {
  content: "\e922";
}

.ciq-filter:before {
  content: "\e923";
}

.ciq-library:before {
  content: "\e924";
}

.ciq-spreadsheets:before {
  content: "\e925";
}

.ciq-search:before {
  content: "\e926";
}

.ciq-administration:before {
  content: "\e927";
}

.ciq-favourite:before {
  content: "\e928";
}

.ciq-edit:before {
  content: "\e929";
}

.ciq-settings:before {
  content: "\e92a";
}

.ciq-dashboard:before {
  content: "\e92b";
}

.ciq-activity:before {
  content: "\e92c";
}

.ciq-schedule:before {
  content: "\e92d";
}

.ciq-notification:before {
  content: "\e92e";
}

.ciq-attachment:before {
  content: "\e92f";
}

.ciq-trash:before {
  content: "\e930";
}

.ciq-more:before {
  content: "\e931";
}

.ciq-deals:before {
  content: "\e932";
}

.ciq-file:before {
  content: "\e933";
}

.ciq-calender:before {
  content: "\e934";
}

.ciq-comment:before {
  content: "\e935";
}

.ciq-card-view:before {
  content: "\e936";
}

.ciq-chevron-left:before {
  content: "\e937";
}

.ciq-chevron-down:before {
  content: "\e938";
}

.ciq-chevron-right:before {
  content: "\e939";
}

.ciq-chevron-up:before {
  content: "\e93a";
}

.ciq-up:before {
  content: "\e93b";
}

.ciq-down:before {
  content: "\e93c";
}

.ciq-minus:before {
  content: "\e93d";
}

.ciq-plus:before {
  content: "\e93e";
}

.ciq-check:before {
  content: "\e93f";
}

.ciq-close:before {
  content: "\e940";
}

.ciq-call-settings:before {
  content: "\e978";
}
